// change default bootstrap font
$font-size-root: $ml-font-size-base;

// the body tag has id="root"
#root {
  @include body-font;

  h1, h2, h3, h4, h5, h6 {
    @include header-font;
  }
  h1.huge { font-size: $ml-font-size-huge; }
  h1 { }
  h2 { }
  h3 {
    color: var(--bs-info-text-emphasis);
  }
  h4 {
    color: var(--bs-info-text-emphasis);

  }
  h5 {
    font-weight: 500;
    color: $ml-gray-dark;
  }
  h6 {
    font-weight: 600;
    color: $ml-gray-dark;
  }
}

$ml4-header: #2F2F30;
$ml4-card: #4C4C4C;
$ml4-orange: #F06624;
$ml4-accent: #F69D45;
$ml4-blue: #23a5c2;
$ml4-text: #ffffff;


//Responsive colors. Use these for new components and styling so that it changes correctly for all themes.
@include color-mode(dark) {
  --bs-body-bg: #2F2F30;
  --bs-secondary-bg: #4C4C4C;
  --bs-tertiary-bg: #3c3c3c;
  --bs-body-color: #ffffff;
  --bs-secondary-bg-subtle: #000000;
  --bs-info-text-emphasis: #ffffff;
  --bs-border-color: #3e3e3e;
  --bs-link-color-rgb: 	101, 202, 237;
  --bs-primary-text-emphasis: #ff9766;
  --bs-secondary-text-emphasis: #f7b597;
    .dropdown-content {
      box-shadow: 0px 0px 10px var(--bs-secondary-bg-subtle);
      clip-path: inset(0px -10px -10px -10px);  
    }
}

@include color-mode(light) {
  --bs-body-bg: #f1f1f1;
  --bs-secondary-bg: #fff;
  --bs-tertiary-bg:#f1f1f1;   
  --bs-info-text-emphasis: #626262;
  --bs-border-color: #bbc;
  --bs-primary-text-emphasis: #F06624;
  --bs-secondary-text-emphasis: #d35113;
}

@mixin ml4-container-mixin {
  // this padding compensates for bootstrap's `margin: 0 -15px;` used on .row.
  padding: 0 ($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
  @include clearfix();

  // shrink outer gutter for narrow screens
  @media (max-width: $ml-screen-sm) {
    padding: 0 4px;
    .row {
      margin: 0 -4px;
    }
  }
}

.close {
  border-color: transparent;
  background-color: transparent;
  position: absolute;
  right: 2%;
}

.base-dropdown > .row {
    margin-right: 0px;
    margin-left: 0px;
}

.movie-card-md1 > .card {
  border: none;
}

.ml4-container-mixin {
  @include ml4-container-mixin();
}

// fluid container (drop-in replacement for .container) with max-width
.ml4-container {
  @include ml4-container-mixin;
  // max-width aligned with 8-wide movie posters in explore page
  max-width: $ml-container-max-width;
}

// fluid container (drop-in replacement for .container) with max-width
.ml4-narrow-container {
  @include ml4-container-mixin;
  max-width: (800px + $grid-gutter-width);
}

// e.g.: <div class="jumbotron ml4-jumbotron">
.jumbotron.ml4-jumbotron {
  padding-left: 0;
  padding-right: 0;
  background-color: inherit;
  p {
    margin-bottom: 36px;
  }
}

.unpadded {
  padding: 0;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.positioned-right {
  float: right;
  position: relative;
  width: 100%;
}

// standard wrapper for ml4-movie-card search results
.ml4-movie-card-search-result {
  margin: 0 4px 36px 0;
  display: inline-block;
  vertical-align: top;
  @media (max-width: $ml-screen-sm) {
    margin: auto;
  }
    
}

.ml4-bordered {
  border: 2px solid transparent;
  width: 100%;
  @include border-radius($ml-border-radius);
  &:hover {
    border-color: $ml-orange;
  }
}

header.compact-header {
  margin: 0;
}

// common page/section header styles
.explore-header {
  font-size: $ml-font-size-h4;
  font-weight: $ml-font-weight-bold;
}
.explore-sub-header {
  font-size: $ml-font-size-small;
  color: $ml-color-med-gray;
}

// looks like text until mouseover
a.plainlink {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline
  }
}

plainlink {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline
  }
}

.mpaa {
  border: 2px solid;
  padding: 2px 6px;
  display: inline-block;
}

.error {
  color: $ml-orange-dark;
}
.success {
  color: $ml-green-shade;
}

.version {
  color: $ml-gray-light;
  font-size: 80%;
}


hr.page-top {
  margin-top: 0;
  margin-bottom: 0;
}

.contentWrapper {
  background-color: $ml-off-white;
}

.default-positioned-alert-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 500px;

  .alert {
    top: 24px;
    right: 6px;
  }
}

.text-center {
  text-align: center;
}


// graph overrides
.nvd3 text {
  fill: var(--bs-body-color) !important;
}
.nvd3 .nv-axis path {
  stroke: var(--bs-body-color) !important;
}

// ------ TWBS overrides ------

// override defaults
ul.dropdown-menu {
  font-size: $ml-font-size-small;
  padding: 6px 10px
}

.dropdown-menu {
  @include box-shadow(none);
}


// ------ angular-strap overrides ------

#root .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
#root .modal-backdrop.dark {
  background-color: rgba(0, 0, 0, 0.9);
}

// templates must add a div with this id, and position it (see base.less)
#alert-box {
  .alert {
    width: 100%;
    display: inline-flex;
    z-index: 101;
  }
}

.cursor-pointer {
  cursor: pointer;
}

// ensure that the typeahead dropdown gets the right cursor
ul.typeahead {
  li > a {
    cursor: pointer;
  }
}

// ensure that dropdown menus get the right cursor and spacing
ul.dropdown-menu {
  li > a {
    cursor: pointer;
    display: flex;
  }
}

.open > .dropdown-menu {
  display: block;
}

// ------ button overrides ------
.btn {
  .btn-default {
    @include button-variant($background, $border-color);
  }
  
}

.btn-default {
  color: var(--bs-dark-text-emphasis);
  background-color: var(--bs-secondary-bg);
  border-color: var(--bs-body-bg);
}

.invisible {
  display: none;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open>.dropdown-toggle.btn-default {
  @include button-variant(darken($background, 15%), $border-color);
}

.modal {
  display: block !important;
}

// Radio Padding (recommender settings)
.radio {
  padding: 5px 0px 5px 0px;
}

.carousel-indicators {
  right: 0;
  left: 0;
  bottom: 5%;
}

[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target] {
  background-color: #fff;
  color: rgba(0, 0, 0, 0);
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}


// ------ typeahead overrides ------

#root .tt-dropdown-menu,
#root .tt-menu {
  font-size: $ml-font-size-small;
}

