@import './app.scss';

.ml4-pages {

  @include body-font;
  color: $ml-color-stormy;

  h1, h2, h3, h4, h5, h6 {
    @include header-font;
  }

  h1.huge { font-size: $ml-font-size-huge; }
  //h1 { .ml-font-display-2; }
  //h2 { .ml-font-display-1; }

  h3 {
    //.ml-font-headline;
    color: $ml-color-med-gray;
  }
  h4 {
    //.ml-font-subhead;
    color: $ml-color-med-gray;
  }
  h5 {
    //.ml-font-body2;
    font-weight: 500;
    color: $ml-color-med-gray;
  }
  h6 {
    //.ml-font-body1;
    font-weight: 600;
    color: $ml-color-med-gray;
  }


  &.root-page {
    background-color: $white;

    .root-section {
      padding-top: 80px;
      color: $ml-font-color-black;
    }
  }

  &.info-page {
    font-size: 120%;
    p, h1, h2, h3, h4, h5, h6 {
      line-height: 180%;
    }
    p {
      margin-top: 20px;
    }
  }

  .base-container {
    padding-top: 20px;

    &.no-header {
      margin-top: 20px;
    }
  }

  h2.section-header {
    margin-top: 0;
    font-size: $ml-font-size-h3;
    font-weight: $ml-font-weight-regular;
    color: $ml-blue;
  }

  img.marketing-img {
    background-color: $white;
    border: 1px solid $ml-gray-lighter;
  }

}


.root-page {

  .base-container {
    max-width: 800px + $grid-gutter-width;
    // this padding compensates for bootstrap's `margin: 0 -15px;` used on .row.
    padding: 0 ($grid-gutter-width / 2);
    margin-right: auto;
    margin-left: auto;
    @include clearfix();
  }

  .site-version {
    margin-top: 20px;
    color: $ml-gray-light;
  }

  .big-header {
    background-color: $ml-orange;
    color: $white;

    div.base-container.big-header-content {
      padding: 120px ($grid-gutter-width / 2);
    }

    h1.root-tagline {
      font-size: 20px;
      font-weight: $ml-font-weight-light;
      color: $white;
      margin-top: 24px;
    }

    div.header-actions {
      margin-top: 30px;
    }

    a.sign-in {
      color: $white;
      text-decoration: underline;
      &:hover {
        color: $ml-font-color-white;
      }
    }

    a.btn-sign-up {
      text-decoration: none;
      font-size: 16px;
      background-color: $ml-off-white;
      border-color: transparent;
      color: $ml-blue-dark;

      &:hover {
        background-color: $ml-gray-lighter;
      }
    }

  }


  .small-header {
    background-color: $ml-orange;
    color: $white;

    div.base-container.small-header-content {
      padding: 20px ($grid-gutter-width / 2);
    }

  }

  .site-footer {
    margin: 100px 0 40px 0;
    border-top: 1px solid $ml-gray-light;

    .base-container {
      color: $ml-gray;
      line-height: 200%;
    }

  }

}