// bootstrap overrides and extensions for ml4
@use "sass:color";
@import "variables.scss";

$brand-primary: $ml-blue;
$body-bg: $ml-off-white;
$text-color: $ml-gray-dark;

$link-decoration: none;

$hr-border: darken($ml-off-white, 10%);
$input-color-placeholder: $ml-gray-light;

$border-radius-base:  $ml-border-radius;
$border-radius-large: $ml-border-radius;
$border-radius-small: $ml-border-radius;

$btn-primary-color:              $white;
$btn-primary-bg:                 $ml-blue;
$btn-primary-border:             darken($btn-primary-bg, 5%);

$btn-success-color:              $white;
$btn-success-bg:                 $ml-green;
$btn-success-border:             darken($btn-success-bg, 5%);

$btn-info-color:                 $white;
$btn-info-bg:                    $ml-gray-light;
$btn-info-border:                darken($btn-info-bg, 5%);

$btn-warning-color:              $white;
$btn-warning-bg:                 $ml-orange;
$btn-warning-border:             darken($btn-warning-bg, 5%);

$btn-danger-color:               $white;
$btn-danger-bg:                  $ml-orange-dark;
$btn-danger-border:              darken($btn-danger-bg, 5%);

$link-color:                  $ml-blue;

$grid-gutter-width: $ml-gutter-width;

$icon-font-path: "/fonts/";

$screen-xs: $ml-screen-xs;
$screen-sm: $ml-screen-sm;
$screen-md: $ml-screen-md;
$screen-lg: $ml-screen-lg;
